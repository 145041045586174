/* eslint-disable react/prop-types */
import * as React from "react";
import { graphql } from "gatsby";
import { Card, CardContent, CardActions, Typography } from "@material-ui/core";
import { Button, CardActionArea } from "gatsby-theme-material-ui";
import Layout from "../components/layout";
import Seo from "../components/seo";

const HomePage = ({ data }) => (
  <Layout>
    <Seo title={data.site.siteMetadata.title} />
    <article className="post-index">
      {data.allMdx.nodes.map(({ id, frontmatter, fields, headings }) => (
        <article key={id} className="post-item" style={{ margin: "2rem" }}>
          <Card variant="outlined">
            <CardActionArea>
              <CardContent>
                <Typography variant="h5" component="h2">
                  {headings[0].value}
                </Typography>
                <Typography variant="body2">{frontmatter.date}</Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button color="primary" to={fields.slug}>
                Read
              </Button>
            </CardActions>
          </Card>
        </article>
      ))}
    </article>
  </Layout>
);

export const query = graphql`
  query SITE_INDEX_QUERY {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(
      filter: { frontmatter: { public: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY.MM")
        }
        headings(depth: h1) {
          value
        }
      }
    }
  }
`;

export default HomePage;
